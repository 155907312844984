import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid'
import { Icon } from '@iconify/react'
import { modals } from '@mantine/modals'

import { PlatformUsername, UnmuteModal, MuteModal } from 'src/components'
import { createCommenterProfileLink } from 'src/utils/socials'
import { PlatformType } from 'src/types'
import { parseToLocalDateTime } from 'src/utils/date'

export const createMutedColumns = (
  handleSuccess: (unmute: boolean) => void
): GridColDef[] => {
  return [
    {
      field: 'commentAuthor',
      headerName: 'Commenter'.toLocaleUpperCase(),
      sortable: false,
      flex: 2,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return (
          <PlatformUsername
            platform={params.row.platform}
            username={params.row.platformUsername}
          />
        )
      },
    },
    {
      field: 'trackedUser',
      headerName: 'To'.toLocaleUpperCase(),
      sortable: false,
      flex: 2,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return (
          <PlatformUsername
            platform={params.value.platform}
            username={params.value.platformUsername}
          />
        )
      },
    },
    {
      field: 'createdAt',
      headerName: 'Muted'.toLocaleUpperCase(),
      sortable: false,
      flex: 1,
      disableColumnMenu: true,
      valueFormatter: (params: any) => parseToLocalDateTime(params.value),
    },
    {
      field: 'expiresAt',
      headerName: 'Expires'.toLocaleUpperCase(),
      sortable: false,
      flex: 1,
      disableColumnMenu: true,
      valueFormatter: (params: any) => {
        if (params.value) {
          return parseToLocalDateTime(params.value)
        } else {
          return 'Never'
        }
      },
    },
    {
      field: 'actions',
      type: 'actions',
      width: 60,
      getActions: (params: GridRowParams) => {
        let actions = [
          <GridActionsCellItem
            icon={<Icon icon="mdi:account-off-outline" />}
            onClick={() =>
              modals.open({
                title: 'Extend Mute',
                centered: true,
                children: (
                  <MuteModal
                    trackedUserId={params.row.trackedUser.id}
                    commentAuthor={params.row.platformUsername}
                    commentAuthorId={params.row.platformId}
                    successCallback={() => handleSuccess(true)}
                    isExtend={true}
                  />
                ),
                size: 'md',
              })
            }
            label="Extend"
            showInMenu
          />,
          <GridActionsCellItem
            icon={<Icon icon="mdi:account-plus-outline" />}
            onClick={() =>
              modals.open({
                title: 'Unmute Commenter',
                centered: true,
                children: (
                  <UnmuteModal
                    trackedUserId={params.row.trackedUser.id}
                    commentAuthor={params.row.platformUsername}
                    commentAuthorId={params.row.platformId}
                    successCallback={() => handleSuccess(true)}
                  />
                ),
                size: 'md',
              })
            }
            label="Unmute"
            showInMenu
          />,
        ]

        if (
          params.row.platform &&
          params.row.platformUsername &&
          params.row.platform !== PlatformType.FACEBOOK
        ) {
          const profileLink = createCommenterProfileLink(
            params.row.platform,
            params.row.platformUsername
          )

          actions.push(
            <GridActionsCellItem
              icon={<Icon icon="mdi:identification-card-outline" />}
              onClick={() => window.open(profileLink, '_blank')}
              label="View Profile"
              showInMenu
            />
          )
        }

        return actions
      },
    },
  ]
}
