import { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { Group, Space, Title } from '@mantine/core'
import { notifications } from '@mantine/notifications'

import { Page, Loading, ActionLogTabs, ActionLogFilters } from 'src/components'
import {
  RootState,
  setShouldActionLogResetPagination,
  setShouldRefreshActionLog,
  setShouldRefreshActionLogTotals,
  setTabChanged,
} from 'src/redux/reducers'
import { useFetchActionLog, useFetchActionLogTotal } from 'src/api'
import {
  ActionLogTotals,
  ActionLog,
  ActionLogSortBy,
  ActionLogSortOrder,
  ActionLogSettings,
} from 'src/types'

const ACTION_LOG_INITIAL_STATE: ActionLog = {
  entries: [],
  totalEntries: 0,
  totalPages: 1,
}

const ACTION_LOG_SETTINGS_INITIAL_STATE__UNRESOLVED: ActionLogSettings = {
  isUnresolved: true,
  pagination: {
    currentPage: 1,
    perPage: 10,
  },
  selectedIds: [],
  sortModel: {
    sortBy: ActionLogSortBy.commentPosted,
    sortOrder: ActionLogSortOrder.desc,
  },
  resolvedTypeFilter: 'UNRESOLVED',
  abuseTypeFilter: 'ALL',
}

const ACTION_LOG_SETTINGS_INITIAL_STATE__RESOLVED: ActionLogSettings = {
  ...ACTION_LOG_SETTINGS_INITIAL_STATE__UNRESOLVED,
  isUnresolved: false,
  resolvedTypeFilter: 'ALL',
}

const ACTION_LOG_TOTALS_INITIAL_STATE = {
  unresolved: 0,
  resolved: 0,
}

export default function ActionLogPage() {
  const dispatch = useDispatch()

  // Retrieve the current URL query params
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  // Retrieve filters from the Redux store
  const filters = useSelector((state: RootState) => state.filters)
  const actionLogState = useSelector((state: RootState) => state.actionLog)

  // State for action log data
  const [errorShown, setErrorShown] = useState(false)
  const [initialDataLoading, setInitialDataLoading] = useState(true)
  const [isUnresolvedTab, setIsUnresolvedTab] = useState(
    params.get('tab') === 'unresolved'
  )

  const [unresolvedActionLogSettings, setUnresolvedActionLogSettings] =
    useState<ActionLogSettings>(ACTION_LOG_SETTINGS_INITIAL_STATE__UNRESOLVED)
  const [resolvedActionLogSettings, setResolvedActionLogSettings] =
    useState<ActionLogSettings>(ACTION_LOG_SETTINGS_INITIAL_STATE__RESOLVED)
  const [actionLogTotals, setActionLogTotals] = useState<ActionLogTotals>(
    ACTION_LOG_TOTALS_INITIAL_STATE
  )
  const [unresolvedActionLog, setUnresolvedActionLog] = useState<ActionLog>(
    ACTION_LOG_INITIAL_STATE
  )
  const [resolvedActionLog, setResolvedActionLog] = useState<ActionLog>(
    ACTION_LOG_INITIAL_STATE
  )

  const { loading, error, fetch: fetchActionLog } = useFetchActionLog()
  const {
    loading: loadingTotal,
    error: errorTotal,
    fetch: fetchTotal,
  } = useFetchActionLogTotal()

  // Fetch action log totals
  const fetchActionLogTotals = useCallback(async () => {
    const unresolved = await fetchTotal({
      isUnresolved: true,
      globalFilters: filters,
      abuseTypeFilter: unresolvedActionLogSettings.abuseTypeFilter,
    })
    const resolved = await fetchTotal({
      isUnresolved: false,
      globalFilters: filters,
      abuseTypeFilter: resolvedActionLogSettings.abuseTypeFilter,
      resolvedTypeFilter: resolvedActionLogSettings.resolvedTypeFilter,
    })

    setActionLogTotals({
      unresolved: unresolved,
      resolved: resolved,
    })

    dispatch(setShouldRefreshActionLogTotals(false))
  }, [
    dispatch,
    fetchTotal,
    filters,
    unresolvedActionLogSettings.abuseTypeFilter,
    resolvedActionLogSettings.abuseTypeFilter,
    resolvedActionLogSettings.resolvedTypeFilter,
  ])

  // Automatically fetch action log totals when shouldRefreshActionLogTotals is true
  // and when loadingTotal is false
  useEffect(() => {
    if (loadingTotal) return

    if (!actionLogState.shouldRefreshActionLogTotals) return

    fetchActionLogTotals()
  }, [
    fetchActionLogTotals,
    loadingTotal,
    actionLogState.shouldRefreshActionLogTotals,
  ])

  // Fetch action log data
  const refetchData = useCallback(
    async (isUnresolved: boolean, shouldResetPagination: boolean = false) => {
      if (isUnresolved) {
        const actionLog = await fetchActionLog({
          globalFilters: filters,
          isUnresolved: true,
          abuseTypeFilter: unresolvedActionLogSettings.abuseTypeFilter,
          pagination: shouldResetPagination
            ? ACTION_LOG_SETTINGS_INITIAL_STATE__UNRESOLVED.pagination
            : unresolvedActionLogSettings.pagination,
          sortModel: unresolvedActionLogSettings.sortModel,
        })

        setUnresolvedActionLog(actionLog)
      } else {
        const actionLog = await fetchActionLog({
          globalFilters: filters,
          isUnresolved: false,
          abuseTypeFilter: resolvedActionLogSettings.abuseTypeFilter,
          pagination: shouldResetPagination
            ? ACTION_LOG_SETTINGS_INITIAL_STATE__RESOLVED.pagination
            : resolvedActionLogSettings.pagination,
          sortModel: resolvedActionLogSettings.sortModel,
          resolvedTypeFilter: resolvedActionLogSettings.resolvedTypeFilter,
        })

        setResolvedActionLog(actionLog)
      }
    },
    [
      fetchActionLog,
      filters,
      resolvedActionLogSettings.abuseTypeFilter,
      resolvedActionLogSettings.pagination,
      resolvedActionLogSettings.sortModel,
      resolvedActionLogSettings.resolvedTypeFilter,
      unresolvedActionLogSettings.abuseTypeFilter,
      unresolvedActionLogSettings.pagination,
      unresolvedActionLogSettings.sortModel,
    ]
  )

  // Automatically fetch action log data when shouldRefreshActionLog is true
  // and when loading is false and initialDataLoading is false
  // and when actionLogState.tabChanged is false
  useEffect(() => {
    if (loading) return

    if (
      !initialDataLoading &&
      !actionLogState.shouldRefreshActionLog &&
      !actionLogState.tabChanged
    )
      return

    dispatch(setShouldRefreshActionLog(false))
    dispatch(setTabChanged(false))

    refetchData(isUnresolvedTab, actionLogState.shouldResetPagination)
  }, [
    dispatch,
    refetchData,
    loading,
    initialDataLoading,
    isUnresolvedTab,
    actionLogState.shouldRefreshActionLog,
    actionLogState.tabChanged,
    actionLogState.shouldResetPagination,
  ])

  // Automatically reset pagination when shouldResetPagination is true
  // and when initialDataLoading is false
  useEffect(() => {
    if (initialDataLoading || !actionLogState.shouldResetPagination) return

    if (isUnresolvedTab) {
      setUnresolvedActionLogSettings(prev => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          currentPage: 1,
        },
      }))
    } else {
      setResolvedActionLogSettings(prev => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          currentPage: 1,
        },
      }))
    }

    dispatch(setShouldActionLogResetPagination(false))
  }, [
    dispatch,
    isUnresolvedTab,
    initialDataLoading,
    actionLogState.shouldResetPagination,
  ])

  // Fetch action log and totals on initial load
  useEffect(() => {
    if (!initialDataLoading) return

    setInitialDataLoading(false)

    refetchData(isUnresolvedTab)
    fetchActionLogTotals()
  }, [initialDataLoading, refetchData, fetchActionLogTotals, isUnresolvedTab])

  useEffect(() => {
    if ((error || errorTotal) && !errorShown && initialDataLoading) {
      setErrorShown(true)
      notifications.show({
        title: 'Uh-oh! Something Went Wrong',
        message:
          'We were unable to load your data. Please refresh to try again.',
        autoClose: 5000,
      })
    }
  }, [error, errorTotal, errorShown, initialDataLoading])

  if (initialDataLoading) return <Loading />

  return (
    <Page noBorder>
      <Group position="apart">
        <Title order={1}>Action Log</Title>
        <ActionLogFilters
          loadingData={loading || loadingTotal}
          filters={filters}
          onChange={() => {
            dispatch(setShouldActionLogResetPagination(true))
            dispatch(setShouldRefreshActionLogTotals(true))
            dispatch(setShouldRefreshActionLog(true))
          }}
        />
      </Group>
      <Space h={32} />
      <ActionLogTabs
        loadingData={loading}
        isUnresolvedTab={isUnresolvedTab}
        setIsUnresolvedTab={setIsUnresolvedTab}
        actionLogTotals={actionLogTotals}
        unresolvedActionLog={unresolvedActionLog || ACTION_LOG_INITIAL_STATE}
        unresolvedActionLogSettings={unresolvedActionLogSettings}
        setUnresolvedActionLogSettings={setUnresolvedActionLogSettings}
        resolvedActionLog={resolvedActionLog || ACTION_LOG_INITIAL_STATE}
        resolvedActionLogSettings={resolvedActionLogSettings}
        setResolvedActionLogSettings={setResolvedActionLogSettings}
      />
    </Page>
  )
}
