import { useState } from 'react'

import { Title, Text, Input, Button } from '@mantine/core'
import { notifications } from '@mantine/notifications'

import { Anchor, ErrorMessage } from 'src/components'
import { useSendResetPasswordRequest } from 'src/api'

export default function ResetEmail() {
  const { loading, error, send } = useSendResetPasswordRequest()
  const [email, setEmail] = useState<string | null>(null)

  const handleSend = async () => {
    const success = await send(email || '')

    if (success) {
      setEmail('')

      notifications.show({
        title: 'Password Reset Email Sent',
        message: `An email has been sent to ${email}. Please check your inbox.`,
        color: 'teal',
      })
    }
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <Title order={1} mb="md">
        No worries, it happens
      </Title>
      <Text>
        Enter the email address linked to your account, and we'll send you a
        link to reset your password:
      </Text>
      <Input
        mb="sm"
        mt="md"
        placeholder="Email"
        type="email"
        value={email || ''}
        onChange={e => setEmail(e.currentTarget.value)}
        required
      />
      <Button
        variant="filled"
        color="primary"
        size="md"
        radius="xl"
        mb={10}
        type="submit"
        loading={loading}
        disabled={loading || !email}
        onClick={handleSend}
      >
        Send
      </Button>
      <ErrorMessage error={error} />

      <Text mb="sm" mt="xl" size="sm">
        If you need help, please reach out to{' '}
        <Anchor to={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
          {process.env.REACT_APP_SUPPORT_EMAIL}
        </Anchor>
        .
      </Text>
      <Text size="sm">
        Remember your password?{' '}
        <Anchor size="sm" to="/login">
          Sign In.
        </Anchor>
      </Text>
    </div>
  )
}
