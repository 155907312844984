import axios, { AxiosInstance } from 'axios'
import camelCaseKeys from 'camelcase-keys'

import { store } from 'src/redux/store'

const client: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
})

// const client: AxiosInstance = axios.create({
//   baseURL: 'https://api.aretolabs.com',
// })

client.interceptors.request.use(
  config => {
    const state = store.getState()
    config.headers['authentication_token'] = state.user.token
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

client.interceptors.response.use(
  response => {
    // only convert to camel-case on non-auth requests
    if (store.getState().user.token) {
      return {
        ...response,
        data: camelCaseKeys(response.data, { deep: true }),
      }
    } else {
      return response
    }
  },
  error => {
    // retry if receive 401 and user is logged in
    // TODO: make sure only retry once
    const state = store.getState()
    if (error.response?.status === 401 && state.user.token) {
      error.config.headers['authentication_token'] = state.user.token
      return client.request(error.config)
    }

    return Promise.reject(error)
  }
)

export default client
