import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  shouldRefreshActionLog: false,
  shouldRefreshActionLogTotals: false,
  shouldResetPagination: false,
  tabChanged: false,
}

const actionLogSlice = createSlice({
  name: 'actionLog',
  initialState,
  reducers: {
    setShouldRefreshActionLog(state, action: PayloadAction<boolean>) {
      state.shouldRefreshActionLog = action.payload
    },
    setShouldRefreshActionLogTotals(state, action: PayloadAction<boolean>) {
      state.shouldRefreshActionLogTotals = action.payload
    },
    setShouldActionLogResetPagination(state, action: PayloadAction<boolean>) {
      state.shouldResetPagination = action.payload
    },
    setTabChanged(state, action: PayloadAction<boolean>) {
      state.tabChanged = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase('user/logout', () => {
      return initialState
    })
  },
})

export const {
  setShouldRefreshActionLog,
  setShouldRefreshActionLogTotals,
  setShouldActionLogResetPagination,
  setTabChanged,
} = actionLogSlice.actions
export default actionLogSlice.reducer
