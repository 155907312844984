import { useState } from 'react'
import client from '../client'

export const useSendResetPasswordRequest = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const send = async (email: string): Promise<boolean> => {
    setLoading(true)
    setError(null)

    try {
      const response = await client.post('/accounts/reset-password-email', {
        email: email,
      })
      return response?.status === 204
    } catch (error: any) {
      setError(error.response.data.response.error)
      return false
    } finally {
      setLoading(false)
    }
  }

  return { loading, error, send }
}
