import { useLocation, useNavigate } from 'react-router'
import { useState } from 'react'

import { Title, Text, Button } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { z } from 'zod'
import { Icon } from '@iconify/react'

import { Anchor, PasswordInput, ErrorMessage } from 'src/components'
import { useResetPasswordWithToken } from 'src/api'

const passwordSchema = z
  .object({
    password: z
      .string()
      .trim()
      .nonempty('This field is required. ')
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.'
      ),
    confirmPassword: z.string().trim().nonempty('This field is required. '),
  })
  .refine(data => data.password === data.confirmPassword, {
    message: 'Passwords must match. ',
    path: ['confirmPassword'],
  })

const INITIAL_STATE = {
  password: '',
  confirmPassword: '',
}

export default function ResetPassword() {
  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const token = params.get('token')

  const { loading, error, send } = useResetPasswordWithToken()
  const [values, setValues] = useState(INITIAL_STATE)
  const [errors, setErrors] = useState<z.ZodError | null>(null)

  const handleSetValue = (key: string, value: string | number | boolean) => {
    setValues(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }

  const handleSubmit = async () => {
    const result = passwordSchema.safeParse(values)

    if (result.success) {
      setErrors(null)

      const success = await send({
        password: values.password,
        token: token || '',
      })

      if (success) {
        notifications.show({
          title: 'Password Has Been Reset',
          message: `Your password has been successfully reset. Please log in with your new password.`,
          color: 'teal',
          autoClose: 10000,
        })

        // Redirect to login page on success
        navigate('/login')
      } else {
        setValues(INITIAL_STATE)
      }
    } else {
      setErrors(result.error)
    }
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <Title order={1} mb="md">
        Reset Password
      </Title>
      <Text align="center" size="sm" mt={16} mb={16}>
        Your password must be at least 8 characters long and contain at least
        one uppercase letter, one lowercase letter, one digit, and one special
        character.
      </Text>
      <PasswordInput
        name="password"
        required
        label="Password"
        placeholder="Your password"
        icon={<Icon icon="carbon:locked" />}
        mb="lg"
        value={values.password}
        onChange={e => handleSetValue('password', e.target.value)}
        error={errors?.formErrors.fieldErrors.password}
      />
      <PasswordInput
        name="confirmPassword"
        required
        label="Confirm password"
        placeholder="Confirm your password"
        icon={<Icon icon="carbon:locked" />}
        mb="sm"
        value={values.confirmPassword}
        onChange={e => handleSetValue('confirmPassword', e.target.value)}
        error={errors?.formErrors.fieldErrors.confirmPassword}
      />
      <Button
        variant="filled"
        color="primary"
        size="md"
        radius="xl"
        type="submit"
        loading={loading}
        disabled={
          loading || values.password === '' || values.confirmPassword === ''
        }
        onClick={handleSubmit}
        mb="sm"
      >
        Submit
      </Button>
      <ErrorMessage error={error} />

      <Text mb="sm" mt="xl" size="sm">
        If you need help, please reach out to{' '}
        <Anchor to={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
          {process.env.REACT_APP_SUPPORT_EMAIL}
        </Anchor>
        .
      </Text>
      <Text size="sm">
        Remember your password?{' '}
        <Anchor size="sm" to="/login">
          Sign In.
        </Anchor>
      </Text>
    </div>
  )
}
