import { useState } from 'react'
import client from '../client'

interface ResetAccountPasswordWithTokenRequest {
  token: string
  password: string
}

export const useResetPasswordWithToken = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const send = async (
    data: ResetAccountPasswordWithTokenRequest
  ): Promise<boolean> => {
    let response
    setLoading(true)

    try {
      response = await client.post('/accounts/account/reset-password', {
        token: data.token,
        new_password: data.password,
      })
    } catch (error: any) {
      setError(error.response.data.response.error)
    } finally {
      setLoading(false)
      return response?.status === 204
    }
  }

  return { loading, error, send }
}
